import PropTypes from 'prop-types'

/**
 * Allow us to set custom robots for a page based on the page tags.
 * @param  {Array} tags
 * @param  {String} overrideReturn
 * @return {String}
 */
const getCustomRobotsFromTags = (
    tags,
    overrideReturn = '',
) => {
    // If this page has been tagged as private,
    // we don't want Google to index it.
    if (tags.includes('private')) {
        return 'noindex, nofollow';
    }

    // Sometimes we have a certain robot we want
    // to use instead of falling back to nothing
    if (overrideReturn.length > 0) {
        return overrideReturn;
    }

    // todo -> Are there any default robots we need/want?
    return '';
}

getCustomRobotsFromTags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
    overrideReturn: PropTypes.string,
}

getCustomRobotsFromTags.defaultProps = {
    overrideReturn: '',
}

export default getCustomRobotsFromTags;
