import React from 'react'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'

// Layout
import Seo from '../components/layout/Seo/Seo'

// Views
import BlogArticleView from '../components/views/BlogArticlePressReleaseView/BlogArticleView'

// Utils
import getCustomRobotsFromTags from '../utils/getCustomRobotsFromTags'

const BlogArticleViewTemplate = ({ data, location }) => {
  const tags = data.prismicBlogArticle.tags;
  const robots = getCustomRobotsFromTags(tags);

  return (
    <>
      <Seo
        title={data.prismicBlogArticle.data.title.text}
        customTitle={data.prismicBlogArticle.data.meta_title}
        description={data.prismicBlogArticle.data.meta_description.text || data.prismicBlogArticle.data.intro.text}
        slug={location.pathname}
        customCanonicalUrl={data.prismicBlogArticle.data.custom_canonical_url}
        image={data.prismicBlogArticle.data.social_image.url || data.prismicBlogArticle.data.featured_image.url}
        robots={robots}
      />
      <BlogArticleView
        data={data}
      />
    </>
  )
}

export default withPreview(BlogArticleViewTemplate)

export const PageQuery = graphql`
  query BlogArticleViewTemplateQuery (
    $uid: String!,
    $date: Date!,
    $blogArticleCategoryUids: [String!]!
  ) {
    prismicBlogArticle (
      uid: {
        eq: $uid
      }
    ) {
      url
      tags
      data {
        title {
          text
        }
        meta_title
        meta_description {
          text
        }
        social_image {
          url
        }
        custom_canonical_url
        intro {
          text
        }
        author {
          isBroken
          document {
            ... on PrismicBlogAuthor {
              id
              data {
                title {
                  text
                }
                role {
                  text
                }
                avatar {
                  ...Image
                }
              }
            }
          }
        }
        categories {
          category {
            isBroken
            document {
              ... on PrismicBlogCategory {
                id
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        featured_image {
          ...Image
        }
        body {
          ... on PrismicBlogArticleBodyText {
            id
            slice_type
            primary {
              rich_text {
                html
              }
            }
          }
          ... on PrismicBlogArticleBodyTextWithInline {
            id
            slice_type
            primary {
              rich_text {
                html
              }
            }
          }
          ... on PrismicBlogArticleBodyImage {
            id
            slice_type
            primary {
              image {
                ...Image
              }
              image_caption {
                html
              }
              width
            }
          }
          ... on PrismicBlogArticleBodyEmbed {
            id
            slice_type
            primary {
              embed {
                ...Embed
              }
            }
          }
          ... on PrismicBlogArticleBodyBlockquote {
            id
            slice_type
            primary {
              blockquote_body {
                html
              }
              blockquote_caption {
                html
              }
            }
          }
        }
      }
    }
    relatedBlogArticles: allPrismicBlogArticle(
      filter: {
        data: {
          date: {
            lte: $date
          },
          categories: {
            elemMatch: {
              category: {
                uid: {
                  in: $blogArticleCategoryUids
                }
              }
            }
          }
        },
        uid: {
          ne: $uid
        }
      },
      sort: {
        order: DESC,
        fields: data___date
      },
      limit: 3
    ) {
      nodes {
        ...BlogArticle
      }
    }
  }
`
