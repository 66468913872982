import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Container from '../../shared/Container/Container'
import ArticleGridWithTitle from '../../shared/ArticleGrid/ArticleGridWithTitle'
import ArticleGridAuthor from '../../shared/ArticleGrid/ArticleGridAuthor'

// Utils
import blogArticleDecorator from '../../../utils/blogArticleDecorator'

// Local
import BlogArticlePressReleaseView from './BlogArticlePressReleaseView'

const BlogArticleView = ({ data }) => {
  return (
    <BlogArticlePressReleaseView
      data={data.prismicBlogArticle.data}
      url={data.prismicBlogArticle.url}
    >
      <Container>
        <ArticleGridAuthor
          avatar={data.prismicBlogArticle.data.author.document.data.avatar}
          className='justify-center mb-40'
          name={data.prismicBlogArticle.data.author.document.data.title.text}
          role={data.prismicBlogArticle.data.author.document.data.role.text}
        />

        {data.relatedBlogArticles.nodes.length > 0 && (
          <ArticleGridWithTitle
            className='mb-32'
            title={{
              raw: [{
                type: 'heading1',
                text: 'Related posts'
              }]
            }}
            articles={blogArticleDecorator(data.relatedBlogArticles.nodes)}
            singleRow
          />
        )}
      </Container>
    </BlogArticlePressReleaseView>
  )
}

BlogArticleView.propTypes = {
  data: PropTypes.object.isRequired
}

export default BlogArticleView
